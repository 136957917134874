<template>
  <div class="page">
    <el-scrollbar class="Yscrollbar" style="height: calc(100% - 8vh)">
      <LabelContent :title="userId=='add'?'人员管理新增':'人员管理修改'" ref="LabelContent"></LabelContent>
      <el-form :inline="true" class="arrange-form" size="small" style="text-align: left">
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="账号：">
            <el-input v-model="setadmin.userAccount" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="用户名:">
            <el-input v-model="setadmin.userName" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="邮箱：">
            <el-input v-model="setadmin.userEmail" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="margin-left: 1vw" label="电话：">
            <el-input style="width: 20vw" v-model="setadmin.userPhone" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-scrollbar>
    <div class="bottomBtnBox">
      <el-button class="long" @click="submit" type="primary" size="small">添加</el-button>
      <el-button class="long" @click="cancel" type="info" size="small">取消</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "TestPage_detail",
  data () {
    return {
      userId: 0,
      radio: "1", //审核
      setadmin: {
        userAccount: "",
        userName: "",
        userEmail: "",
        userPhone: "",
      },
    }
  },
  mounted () {
    this.userId = this.$route.params.userId
    if (this.userId != 'add') {
      this.setadmin.userId = this.userId
      this.getuser()
    }
  },
  methods: {
    async getuser () {
      let { data } = await this.$http
        .request({
          url: "/hw/admin/user/detail/" + this.userId,
          method: "GET",
        })
      this.setadmin = data
    },
    async submit () {
      let data = await this.$http
        .request({
          url: "/hw/admin/user/save",
          method: "POST",
          data: this.setadmin,
          showLoading: true,
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        })
      if (data.status == 100) {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.jump("/useradmin")
      }
    },
    cancel () {
      this.jump("/useradmin")
    }
  },
};
</script>

<style lang="scss" scoped>
.el-col-6 {
  width: 20vw;
}
</style>
